body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bottom-bar {
  background: #cecece;
  color: #000000;
  position: sticky;
  bottom: 0px;
  padding: 1em 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the thumb (the draggable part) */
  border-radius: 5px;
}

::-ms-scrollbar {
  width: 8px;
  border-radius: 5px;
}
::-ms-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

/* For Webkit browsers (Chrome, Safari, etc.) */
.notificationScroll::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.notificationScroll::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the thumb (the draggable part) */
}

/* For Firefox */
/* EdgeHTML-based Microsoft Edge */
.notificationScroll::-ms-scrollbar {
  width: 8px;
}

.notificationScroll::-ms-scrollbar-thumb {
  background-color: #888;
}

.banner-height {
  min-height: 400px;
}

@media (max-width: 1024px) {
  .banner-height {
    min-height: 260px;
  }
}

@media (max-width: 660px) {
  .banner-height {
    min-height: 180px;
  }
}

@media (max-width: 530px) {
  .banner-height {
    min-height: 160px;
  }
}
